import React from 'react'
import Header from '../../commonPages/Header'
import CallAction from '../Home/CallAction'
import { Link } from 'react-router-dom'
import WhyUs from './WhyUs'

function SEO() {
    return (
        <>
            <Header />
            <div id="content" className="site-content">
                <div className="rs-breadcrumbs img3">
                    <div className="breadcrumbs-inner text-center">
                        <h1 className="page-title">Search Engine Optimization</h1>
                        <ul>
                            <li title="Codeyes Infotech - IT Solutions and Technology Startup HTML Template">
                                <Link className="active" to="/">
                                    Home
                                </Link>
                            </li>
                            <li title="Go To Services">
                                <Link className="active" to="#">
                                    Services
                                </Link>
                            </li>
                            <li>SEO</li>
                        </ul>
                    </div>
                </div>
                <div id="rs-about" className="rs-about services pt-120 pb-120 md-pt-70">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="sec-title mb-50">
                                    <div className="sub-text style4-bg">SEO</div>
                                    <h2 className="title pb-20">
                                        Search Engine Optimization
                                    </h2>
                                    <div className="desc">
                                        SEO services are instrumental in driving both quantitative and qualitative traffic to your website. In today's fiercely competitive market, securing a top position in search results is paramount. However, selecting the right SEO agency is crucial. Our team utilizes state-of-the-art SEO techniques, underpinned by cutting-edge digital strategies, to elevate traffic and rankings for validated keywords and long-tail phrases, driving valuable business to your website. We possess the expertise to design your desired website and execute a targeted SEO strategy, complemented by our extensive array of search engine optimization services
                                    </div>
                                    <ul className="listing-style mt-30">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Technical SEO</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>On-Site SEO</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>YouTube SEO</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Ecommerce SEO</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Keyword Research and SEO Strategy</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Link Building and Digital PR</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Local SEO</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="about-img">
                                    <img src="assets/images/about/about-16.webp" alt="About" />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6" />
                                    <div className="col-lg-6">
                                        <div className="addon-services">
                                            <div className="icon-images">
                                                <img src="assets/logo/logo512.png" alt="Images" />
                                            </div>
                                            <div className="services-text">
                                                <h2 className="title"> Leading Codeyes Agency Since 2018</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <WhyUs />
            </div>
            <CallAction />
        </>
    )
}

export default SEO