import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

function AboutCounter() {
    return (
        <>
            {/* About Counter Start*/}
            <section className="about-counter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="s-counter4 why">
                                <div className="rs-counter style3 modify3 pt-90">
                                    <div className="container">
                                        <div className="counter-top-area">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6 col-sm-6 md-pb-50">
                                                    <div className="counter-list">
                                                        <div className="counter-text">
                                                            <div className="count-number">
                                                                <CountUp end={50} redraw={true}>
                                                                    {({ countUpRef, start }) => (
                                                                        <VisibilitySensor onChange={start} delayedCall>
                                                                            <span ref={countUpRef} className="rs-count plus"></span>
                                                                        </VisibilitySensor>
                                                                    )}
                                                                </CountUp>
                                                            </div>
                                                            <h3 className="title">Finished Projects</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 md-pb-50">
                                                    <div className="counter-list">
                                                        <div className="counter-text">
                                                            <div className="count-number">
                                                                <CountUp end={18} redraw={true}>
                                                                    {({ countUpRef, start }) => (
                                                                        <VisibilitySensor onChange={start} delayedCall>
                                                                            <span ref={countUpRef} className="rs-count plus"></span>
                                                                        </VisibilitySensor>
                                                                    )}
                                                                </CountUp>
                                                            </div>
                                                            <h3 className="title">Created Jobs</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 xs-pb-50">
                                                    <div className="counter-list">
                                                        <div className="counter-text">
                                                            <div className="count-number">
                                                                <CountUp end={50} redraw={true}>
                                                                    {({ countUpRef, start }) => (
                                                                        <VisibilitySensor onChange={start} delayedCall>
                                                                            <span ref={countUpRef} className="rs-count plus"></span>
                                                                        </VisibilitySensor>
                                                                    )}
                                                                </CountUp>
                                                            </div>
                                                            <h3 className="title">Happy Clients</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                    <div className="counter-list">
                                                        <div className="counter-text">
                                                            <div className="count-number">
                                                                <CountUp end={5} redraw={true}>
                                                                    {({ countUpRef, start }) => (
                                                                        <VisibilitySensor onChange={start} delayedCall>
                                                                            <span ref={countUpRef} className="rs-count plus"></span>
                                                                        </VisibilitySensor>
                                                                    )}
                                                                </CountUp>
                                                            </div>
                                                            <h3 className="title">Years Of Exprience</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutCounter