import React from 'react'

function WhyUs() {
    return (
        <>
            <div className="rs-why-choose pt-120 pb-120 md-pt-70 md-pb-75">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 hidden-md">
                            <div className="image-part">
                                <img src="assets/images/choose/2.webp" alt="image" />
                            </div>
                            <div className="animation style2">
                                <div className="top-shape">
                                    <img
                                        className="dance"
                                        src="assets/images/choose/dotted-2.webp"
                                        alt="images"
                                    />
                                </div>
                                <div className="bottom-shape">
                                    <img
                                        className="dance2"
                                        src="assets/images/choose/dotted-1.webp"
                                        alt="images"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-15">
                            <div className="sec-title mb-40">
                                <div className="sub-text style4-bg left">Why Choose Us</div>
                                <h2 className="title pb-20">
                                    Why Our <span>Clients</span> Choose to <span>Work</span> With Us
                                </h2>
                                <div className="desc">
                                    We have worked with hundreds of businesses like yours and helped them achieve their milestones. Discuss your milestones with our team and see how we help your business.
                                </div>
                            </div>
                            <div className="services-wrap mb-25 ml-0 row">
                                <div className="services-icon col-2" style={{ alignSelf: "self-start" }}>
                                    <img src="assets/images/choose/icons/1.webp" alt="image" />
                                </div>
                                <div className="services-text col-10">
                                    <h3 className="title">
                                        <a href="#">Latest Technologies</a>
                                    </h3>
                                    <p className="services-txt">
                                        We keep updating our self by learning new technologies and implementing for client to get better result.
                                    </p>
                                </div>
                            </div>
                            <div className="services-wrap mb-25 ml-0 row">
                                <div className="services-icon col-2" style={{ alignSelf: "self-start" }}>
                                    <img src="assets/images/choose/icons/2.png" alt="image" />
                                </div>
                                <div className="services-text col-10">
                                    <h3 className="title">
                                        <a href="#">Uniqe Solutions</a>
                                    </h3>
                                    <p className="services-txt">
                                        Uniqe solution is the production or implementation of a creative idea. If you have ideas, but don’t act on them, you are imaginative but not creative.
                                    </p>
                                </div>
                            </div>
                            <div className="services-wrap row ml-0">
                                <div className="services-icon col-2" style={{ alignSelf: "self-start" }}>
                                    <img src="assets/images/choose/icons/3.webp" alt="image" />
                                </div>
                                <div className="services-text col-10">
                                    <h3 className="title">
                                        <a href="#">Powerful Strategies</a>
                                    </h3>
                                    <p className="services-txt">
                                        Proper planning before the negotiation ensures you know the long-term consequences of the negotiation terms. Planning comes in handy during the negotiation process and ensures the successful execution of the decision.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyUs