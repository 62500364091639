import React from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import Header from '../../commonPages/Header';
import CallAction from '../Home/CallAction';
import Services from '../Home/Services';

const owlOptions = {
    loop: true,
    items: 3,
    margin: 30,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 800,
    dots: false,
    nav: false,
    navSpeed: false,
    center: false,
    responsive: {
        0: {
            items: 1, // Mobile
            nav: false,
            dots: false,
        },
        768: {
            items: 2, // iPad
            nav: false,
            dots: false,
        },
        992: {
            items: 3, // Medium devices
            nav: false,
            dots: false,
        },
    },
};

function WhyChooseUs() {
    return (
        <>
            <Header />
            <div id="content" className="site-content">
                {/* Breadcrumbs Start */}
                <div className="rs-breadcrumbs img3">
                    <div className="breadcrumbs-inner text-center">
                        <h1 className="page-title">Why Choose Us</h1>
                        <ul>
                            <li title="Codeyes Infotech - IT Solutions and Technology Startup HTML Template">
                                <Link className="active" to="/">
                                    Home
                                </Link>
                            </li>
                            <li>Why Choose Us</li>
                        </ul>
                    </div>
                </div>
                {/* Breadcrumbs End */}
                <section className="rs-why-choose why-choose-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-5">
                                <img src="assets/images/engi/man1.png" alt="image" />
                            </div>
                            <div className="col-lg-6 md-pb-60">
                                <div className="sec-title mb-25">
                                    <div className="sub-text style4-bg">Why Choose Us</div>
                                    <h2 className="title pb-20">
                                        Why Our <span>Clients</span> Choose to <span>Work</span> With Us
                                    </h2>
                                    <div className="desc">
                                        We have worked with hundreds of businesses like yours and helped them achieve their milestones. Discuss your milestones with our team and see how we help your business.
                                    </div>
                                </div>
                                <div className="services-wrap mb-25">
                                    <div className="services-icon">
                                        <img src="assets/images/choose/icons/1.webp" alt="image" />
                                    </div>
                                    <div className="services-text">
                                        <h3 className="title">
                                            <a href="#">Latest Technologies</a>
                                        </h3>
                                        <p className="services-txt">
                                            We continuously learn and implement new technologies to improve client outcomes.
                                        </p>
                                    </div>
                                </div>
                                <div className="services-wrap mb-25">
                                    <div className="services-icon">
                                        <img src="assets/images/choose/icons/2.png" alt="image" />
                                    </div>
                                    <div className="services-text">
                                        <h3 className="title">
                                            <a href="#">Uniqe Solutions</a>
                                        </h3>
                                        <p className="services-txt">
                                            Unique solutions result from creative idea implementation. Imagination alone falls short.
                                        </p>
                                    </div>
                                </div>
                                <div className="services-wrap">
                                    <div className="services-icon">
                                        <img src="assets/images/choose/icons/3.webp" alt="image" />
                                    </div>
                                    <div className="services-text">
                                        <h3 className="title">
                                            <a href="#">Powerful Strategies</a>
                                        </h3>
                                        <p className="services-txt">
                                            Pre-negotiation planning predicts long-term impact and guides successful execution.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-counter">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="s-counter4 why">
                                    <div className="rs-counter style3 modify3 pt-90">
                                        <div className="container">
                                            <div className="counter-top-area">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-6 col-sm-6 md-pb-50">
                                                        <div className="counter-list">
                                                            <div className="counter-text">
                                                                <div className="count-number">
                                                                    <CountUp end={50} redraw={true}>
                                                                        {({ countUpRef, start }) => (
                                                                            <VisibilitySensor onChange={start} delayedCall>
                                                                                <span ref={countUpRef} className="rs-count plus"></span>
                                                                            </VisibilitySensor>
                                                                        )}
                                                                    </CountUp>
                                                                </div>
                                                                <h3 className="title">Finished Projects</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-sm-6 md-pb-50">
                                                        <div className="counter-list">
                                                            <div className="counter-text">
                                                                <div className="count-number">
                                                                    <CountUp end={18} redraw={true}>
                                                                        {({ countUpRef, start }) => (
                                                                            <VisibilitySensor onChange={start} delayedCall>
                                                                                <span ref={countUpRef} className="rs-count plus"></span>
                                                                            </VisibilitySensor>
                                                                        )}
                                                                    </CountUp>
                                                                </div>
                                                                <h3 className="title">Created Jobs</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-sm-6 xs-pb-50">
                                                        <div className="counter-list">
                                                            <div className="counter-text">
                                                                <div className="count-number">
                                                                    <CountUp end={50} redraw={true}>
                                                                        {({ countUpRef, start }) => (
                                                                            <VisibilitySensor onChange={start} delayedCall>
                                                                                <span ref={countUpRef} className="rs-count plus"></span>
                                                                            </VisibilitySensor>
                                                                        )}
                                                                    </CountUp>
                                                                </div>
                                                                <h3 className="title">Happy Clients</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                                        <div className="counter-list">
                                                            <div className="counter-text">
                                                                <div className="count-number">
                                                                    <CountUp end={5} redraw={true}>
                                                                        {({ countUpRef, start }) => (
                                                                            <VisibilitySensor onChange={start} delayedCall>
                                                                                <span ref={countUpRef} className="rs-count plus"></span>
                                                                            </VisibilitySensor>
                                                                        )}
                                                                    </CountUp>
                                                                </div>
                                                                <h3 className="title">Years Of Exprience</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Services />
                </section>
            </div>
            <CallAction />
        </>
    )
}

export default WhyChooseUs