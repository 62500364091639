import React from 'react'
import { Link } from 'react-router-dom'

const handleLinkClick = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

function CallAction() {
    return (
        <>
            {/* Call Action Section Start */}
            <div className="rs-call-action style2 bg23 pb-60 pt-70 md-pt-70 md-pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="sec-title6 mb-30">
                                <h2 className="title">
                                    Let’s start a <span>Cool Project</span>
                                    <br /> with <span>Codeyes</span> Infotech
                                </h2>
                            </div>
                            <div className="btn-part md-center">
                                <Link className="readon started get-ready5" to="/contact" onClick={handleLinkClick}>
                                    Get Started
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* Call Action Section End */}
        </>

    )
}

export default CallAction