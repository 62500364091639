const clientDetail = [
    { id: 1, logo: "13.webp" },
    { id: 2, logo: "12.webp" },
    { id: 3, logo: "2.webp" },
    { id: 4, logo: "15.webp" },
    { id: 5, logo: "14.webp" },
    { id: 6, logo: "6.webp" },
    { id: 7, logo: "11.webp" },
    { id: 8, logo: "8.webp" },
    { id: 9, logo: "16.webp" },
    { id: 10, logo: "17.webp" },
    { id: 11, logo: "18.webp" },
    { id: 12, logo: "19.webp" },
];
const testimonials = [
    { id: 1, name: "Patrick Bernicker", designation: "UK", img: "male.webp", description: "The outsourcing team is easy to work with, an excellent communicator, knowledgeable about Quickbooks Online and Desktop, and professional in onboarding, setup, and education." },
    { id: 2, name: "Joy Mba", designation: "USA", img: "female.webp", description: "Expertise and an understanding of needs ensure efficient results, exceeding expectations, and exceptional service." },
    { id: 3, name: "Sara Redaelli", designation: "USA", img: "female.webp", description: "Excellent customer service and response were provided by Codeyes. Additionally, working with the developer team was enjoyable. The designs are fantastic, and I would definitely suggest them. Actually, Innovation Bey... " },
    { id: 4, name: "Lino D'Antuono", designation: "Italy", img: "male.webp", description: "complimenti ottimi sviluppatori" },
    { id: 5, name: "Olumuyiwa Folawiyo", designation: "Girona, Spain", img: "male.webp", description: "Working with Codeyes Infotech Company was an absolute pleasure. They delivered the website ahead of schedule and exceeded my expectations. Communication was seamless throughout the project. I highly recommend Codeyes Infotech Company for their skills and professionalism. I look forward to collaborating again in the future!" },
];

const servicedetails = [
    { id: 1, img1: '2.webp', slug: '/web-development', title: 'Web Development', desc: "With the help of our website design services, communicate with your clients using a beautiful, simple-to-use website.", bgclass: "blue-bg" },
    { id: 2, img1: '1.webp', slug: '/mobile-development', title: 'Mobile App Development', desc: "These days, mobile applications are highly helpful to everyone. Utilising your mobile app, expand your business. Adsum Originator will assist you in achieving your professional objectives.", bgclass: "paste-bg" },
    { id: 3, img1: '3.webp', slug: '/ui-ux-design', title: 'UI/UX Design', desc: "Strategic UI/UX design enhances products, meeting user expectations, improving efficiency, and fostering innovation through the latest design trends and tools.", bgclass: "gold-bg" },
    { id: 4, img1: '4.webp', slug: '/seo', title: 'SEO', desc: "Search engine optimization (SEO) is the process of affecting the visibility of a website or a web page in a web search engine’s unpaid results.", bgclass: "cyan-bg" },
    { id: 5, img1: '5.webp', slug: '/digital-marketing', title: 'Digital Marketing', desc: "Benefits of Digital Marketing Digital marketing is one of the highly cost-effec- tive ways of promoting small as well as medium businesses.", bgclass: "pink-light-bg" },
    { id: 6, img1: '6.webp', slug: '/software-development', title: 'Software Development', desc: "Our software developers craft goal-oriented strategic plans, harnessing modern technology to create standout, scalable, and effective software solutions that cater to business and customer needs.", bgclass: "orange-bg" },
];

const ourteamdetails = [
    { id: 1, Image: "member-1.jfif", name: "Amit Patel", position: "Founder of company" },
    { id: 2, Image: "member-2.jfif", name: "Yesha Gevariya", position: "Co-Founder of company" },
    { id: 3, Image: "member-4.jfif", name: "Nik Patel", position: "CTO OF COMPANY" },
];

const webapptechnology = [
    { id: 1, Image: "35.png" },
    { id: 2, Image: "31.png" },
    { id: 3, Image: "20.png" },
    { id: 4, Image: "24.png" },
    { id: 5, Image: "28.png" },
    { id: 6, Image: "29.png" },
    { id: 7, Image: "33.png" },
    { id: 8, Image: "8.png" },
    { id: 9, Image: "11.png" },
    { id: 10, Image: "12.png" },
    { id: 11, Image: "17.png" },
    { id: 12, Image: "26.png" },
    { id: 13, Image: "22.png" },
    { id: 14, Image: "23.png" },
    { id: 15, Image: "25.png" },
    { id: 16, Image: "36.png" },
    { id: 17, Image: "32.png" },
    { id: 18, Image: "34.png" },
    { id: 19, Image: "37.png" },
    { id: 20, Image: "38.png" },
    { id: 21, Image: "39.png" },
    { id: 22, Image: "30.png" },
];

const mobileapptechnology = [
    { id: 1, Image: "1.png" },
    { id: 2, Image: "2.png" },
    { id: 3, Image: "3.png" },
    { id: 4, Image: "4.png" },
    { id: 5, Image: "5.png" }
];

const softwaretechnology = [
    { id: 1, Image: "1.png" },
    { id: 2, Image: "2.png" },
    { id: 3, Image: "3.png" },
    { id: 4, Image: "36.png" },
    { id: 5, Image: "32.png" }
];

const uiuxdesigntechnology = [
    { id: 1, Image: "1.png" },
    { id: 3, Image: "3.png" },
    { id: 4, Image: "4.png" },
    { id: 5, Image: "5.png" },
    { id: 6, Image: "6.png" },
    { id: 7, Image: "7.png" }
];

const AllProject = [
    {
        img: 'assets/images/portfolio/web/attachment.webp',
        category: 'Web Design',
        title: 'Zelios Real Estate',
    },
    {
        img: 'assets/images/portfolio/web/Frame 1.webp',
        category: 'Web Design',
        title: 'Modern Web Page',
    },
    {
        img: 'assets/images/portfolio/web/sageacademy.png',
        category: 'Web Design',
        title: 'Sage Academy',
    },
    {
        img: 'assets/images/portfolio/web/indianbeauty.png',
        category: 'Web Design',
        title: 'Indian Beuty',
    },
    {
        img: 'assets/images/portfolio/web/Frame 2.webp',
        category: 'Web Design',
        title: 'Connect Future',
    },
    // {
    //     img: 'assets/images/portfolio/web/Frame 12.webp',
    //     category: 'Web Design',
    //     title: 'Desire Connect',
    // },
    {
        img: 'assets/images/portfolio/web/Frame 13.webp',
        category: 'Web Design',
        title: 'Welly',
    },
    {
        img: 'assets/images/portfolio/web/Frame 14.webp',
        category: 'Web Design',
        title: 'Maapilim',
    },
    {
        img: 'assets/images/portfolio/web/Frame 18.webp',
        category: 'Web Design',
        title: 'Ella & Sunday',
    },
    {
        img: 'assets/images/portfolio/web/post.webp',
        category: 'Web Design',
        title: 'Fox',
    },
    {
        img: 'assets/images/portfolio/web/dermoco.png',
        category: 'Web Design',
        title: 'Dermoco',
    },

    {
        img: 'assets/images/portfolio/web/happylady.png',
        category: 'Web Design',
        title: 'Happy Lady',
    },
    {
        img: 'assets/images/portfolio/web/newkarta.png',
        category: 'Web Design',
        title: 'NewKarta',
    },



    // {
    //     img: 'assets/images/portfolio/mobile/Frame 15.webp',
    //     category: 'Mobile App',
    //     title: 'E-commerce',
    // },
    // {
    //     img: 'assets/images/portfolio/mobile/Frame 16.webp',
    //     category: 'Mobile App',
    //     title: 'John Lewis',
    // },
    // {
    //     img: 'assets/images/portfolio/mobile/Frame 17.webp',
    //     category: 'Mobile App',
    //     title: 'Ayursh',
    // },
    // {
    //     img: 'assets/images/portfolio/mobile/Frame 19.webp',
    //     category: 'Mobile App',
    //     title: 'Black Horse',
    // },
]

const MobileApp = [
    {
        img: 'assets/images/portfolio/mobile/Frame 15.webp',
        title: 'E-commerce',
        category: 'Mobile App',
    },
    {
        img: 'assets/images/portfolio/mobile/Frame 16.webp',
        title: 'John Lewis',
        category: 'Mobile App',
    },
    {
        img: 'assets/images/portfolio/mobile/Frame 17.webp',
        title: '	Ayursh',
        category: 'Mobile App',
    },
    {
        img: 'assets/images/portfolio/mobile/Frame 19.webp',
        title: 'Black Horse',
        category: 'Mobile App',
    },

]

const WebDesign = [
    {
        img: 'assets/images/portfolio/web/attachment.webp',
        category: 'Web Design',
        title: 'Zelios Real Estate',
    },
    {
        img: 'assets/images/portfolio/web/Frame 1.webp',
        category: 'Web Design',
        title: 'Modern Web Page',
    },
    {
        img: 'assets/images/portfolio/web/Frame 2.webp',
        category: 'Web Design',
        title: 'Connect Future',
    },
    {
        img: 'assets/images/portfolio/web/Frame 12.webp',
        category: 'Web Design',
        title: 'Desire Connect',
    },
    {
        img: 'assets/images/portfolio/web/Frame 13.webp',
        category: 'Web Design',
        title: 'Welly',
    },
    {
        img: 'assets/images/portfolio/web/Frame 14.webp',
        category: 'Web Design',
        title: 'Maapilim',
    },
    {
        img: 'assets/images/portfolio/web/Frame 18.webp',
        category: 'Web Design',
        title: 'Ella & Sunday',
    },
    {
        img: 'assets/images/portfolio/web/post.webp',
        category: 'Web Design',
        title: 'Fox',
    }
]

export {
    AllProject,
    MobileApp,
    WebDesign, clientDetail, mobileapptechnology, ourteamdetails, servicedetails, softwaretechnology, testimonials, uiuxdesigntechnology, webapptechnology
};
