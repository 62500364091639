import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { Link } from 'react-router-dom'
import { AllProject, MobileApp, WebDesign } from '../../../data/data'

function Project() {
	return (
		<>
			{/* Project Section Start */}
			<div
				id="rs-project"
				className="rs-project style8 modify1 pt-110 pb-120 md-pt-70 md-pb-80"
			>
				<div className="container">
					<div className="sec-title6 mb-30 text-center">
						<span className="sub-text new-text">Our Portfolio</span>
						<h2 className="title">
							Projects we've <span>undertaken</span> that are
							<br /> <span>Successful</span> and Exceptional
						</h2>
					</div>
					<Tabs
						defaultActiveKey="All"
						id="fill-tab-example"
						className="mb-3 col-6 m-auto nik"
						fill
					>
						<Tab
							eventKey="All"
							title="All "
							style={{ marginTop: '15px' }}
						>
							<ResponsiveMasonry
								columnsCountBreakPoints={{
									350: 1,
									750: 2,
									900: 3,
								}}
								gutter="10px"
							>
								<Masonry style={{ gap: 15 }}>
									{AllProject.map((item, i) => {
										return (
											<div
												key={i}
												className="project-item mb-30 mr-10"
												style={{
													height:
														i % 2
															? '500px'
															: '300px',
													objectFit: 'cover',
												}}
											>
												<div
													className="project-img"
													style={{
														objectFit: 'cover',
														height: '100%',
													}}
												>
													<img
														style={{
															objectFit: 'cover',
															height: '100%',
														}}
														src={item.img}
														alt="images"
													/>
												</div>
												<div className="project-content">
													<div className="project-inner">
														<h3 className="title">
															<Link to="/portfolio">
																{item.category}
															</Link>
														</h3>
														<span className="category">
															<Link to="/portfolio">
																{item.title}
															</Link>
														</span>
													</div>
												</div>
											</div>
										)
									})}
								</Masonry>
							</ResponsiveMasonry>
						</Tab>

						{/* <Tab
							eventKey="Mobile-App"
							title="Mobile App"
							style={{ marginTop: '15px' }}
						>
							<ResponsiveMasonry
								columnsCountBreakPoints={{
									350: 1,
									750: 2,
									900: 3,
								}}
								gutter="10px"
							>
								<Masonry style={{ gap: 15 }}>
									{MobileApp.map((item, i) => {
										return (
											<div
												key={i}
												className="project-item mb-30 mr-10"
												style={{
													height:
														i % 2
															? '500px'
															: '300px',
													objectFit: 'cover',
												}}
											>
												<div
													className="project-img"
													style={{
														objectFit: 'cover',
														height: '100%',
													}}
												>
													<img
														style={{
															objectFit: 'cover',
															height: '100%',
														}}
														src={item.img}
														alt="images"
													/>
												</div>
												<div className="project-content">
													<div className="project-inner">
														<h3 className="title">
															<Link to="/portfolio">
																{item.category}
															</Link>
														</h3>
														<span className="category">
															<Link to="/portfolio">
																{item.title}
															</Link>
														</span>
													</div>
												</div>
											</div>
										)
									})}
								</Masonry>
							</ResponsiveMasonry>
						</Tab>

						<Tab
							eventKey="Web-Design"
							title="Web Design"
							style={{ marginTop: '15px' }}
						>
							<ResponsiveMasonry
								columnsCountBreakPoints={{
									350: 1,
									750: 2,
									900: 3,
								}}
								gutter="10px"
							>
								<Masonry style={{ gap: 15 }}>
									{WebDesign.map((item, i) => {
										return (
											<div
												key={i}
												className="project-item mb-30 mr-10"
												style={{
													height:
														i % 2
															? '500px'
															: '300px',
													objectFit: 'cover',
												}}
											>
												<div
													className="project-img"
													style={{
														objectFit: 'cover',
														height: '100%',
													}}
												>
													<img
														style={{
															objectFit: 'cover',
															height: '100%',
														}}
														src={item.img}
														alt="images"
													/>
												</div>
												<div className="project-content">
													<div className="project-inner">
														<h3 className="title">
															<Link to="/portfolio">
																{item.category}
															</Link>
														</h3>
														<span className="category">
															<Link to="/portfolio">
																{item.title}
															</Link>
														</span>
													</div>
												</div>
											</div>
										)
									})}
								</Masonry>
							</ResponsiveMasonry>
						</Tab> */}
					</Tabs>
				</div>
			</div>
			{/* Project Section End */}
		</>
	)
}

export default Project
