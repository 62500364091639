import React from 'react'
import { Link } from 'react-router-dom'
import { uiuxdesigntechnology } from '../../../data/data'
import Header from '../../commonPages/Header'
import CallAction from '../Home/CallAction'
import WhyUs from './WhyUs'


function UiUxdesign() {
    return (
        <>
            <Header />
            <div id="content" className="site-content">
                <div className="rs-breadcrumbs img3">
                    <div className="breadcrumbs-inner text-center">
                        <h1 className="page-title">UI/UX Design</h1>
                        <ul>
                            <li title="Codeyes Infotech - IT Solutions and Technology Startup HTML Template">
                                <Link className="active" to="/">
                                    Home
                                </Link>
                            </li>
                            <li title="Go To Services">
                                <Link className="active" to="#">
                                    Services
                                </Link>
                            </li>
                            <li>UI/UX Design</li>
                        </ul>
                    </div>
                </div>
                <div id="rs-about" className="rs-about services pt-120 pb-120 md-pt-70">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="sec-title mb-50">
                                    <div className="sub-text style4-bg">UI/UX Design</div>
                                    <h2 className="title pb-20">
                                        UI/UX Design
                                    </h2>
                                    <div className="desc">
                                        We are experts in UI/UX design and development services, and we put your users' demands at the center of the design of your digital solution. UI/UX web design and development firm focused on the realm of linked products. It assists its clients in designing intuitive and appealing interfaces and optimizing their services. With over a decade of expertise in UI/UX design and development services, we have worked with both SMEs and larger businesses. Since its inception, the firm has always prioritized UI/UX Design while assisting its clients.
                                    </div>
                                    <ul className="listing-style mt-30">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Increased Workflow Efficiency</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Digital Experience Enhancement</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Provides a Positive User Experience</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Interface Excellence</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Objective-Centric Design</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Low Maintenance Cost</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Enhance security while providing a seamless user experience.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="about-img">
                                    <img src="assets/images/about/about-16.webp" alt="About" />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6" />
                                    <div className="col-lg-6">
                                        <div className="addon-services">
                                            <div className="icon-images">
                                                <img src="assets/logo/logo512.png" alt="Images" />
                                            </div>
                                            <div className="services-text">
                                                <h2 className="title"> Leading Codeyes Agency Since 2018</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rs-technology gray-color pt-120 pb-120 md-pt-80 md-pb-80">
                    <div className="container">
                        <div className="sec-title2 text-center mb-45">
                            <span className="sub-text gold-color">Technology Index</span>
                            <h2 className="title title2">
                                What Technology We Are Using For Our Valued Customers
                            </h2>
                        </div>
                        <div className="row">
                            {uiuxdesigntechnology.map((data, index) =>
                                <div className="col-lg-2 col-md-4 col-sm-4 col-6" key={`web-${index}`}>
                                    <div className="technology-item">
                                        <a href="#">
                                            <div className="logo-img" >
                                                <img src={"assets/images/technology/style2/UIUX/" + data.Image} alt="image"  />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="btn-part mt-30">
                                <Link  className="readon started" to="/contact">
                                Get Started
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <WhyUs />
            </div>
            <CallAction />
        </>
    )
}

export default UiUxdesign