import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

function AboutOffer() {
  return (
    <>
      {/* About Offer Start */}
      <section className="rs-about about-offer">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 md-mb-50">
              <div className="sec-title mb-50">
                <div className="sub-text style4-bg">About Us</div>
                <h2 className="title pb-20">
                  Since <span>2018</span>, clients all across the world have put their trust in us.
                </h2>
                <div className="desc">
                  Codeyes provides software, mobile applications, website, development, and other services to satisfy clients needs and enhance their customer relation management needs. Codeyes provides quality services and maintains a high standard for development.
                </div>
              </div>
              <div className="rs-counter style3">
                <div className="container">
                  <div className="counter-top-area">
                    <div className="row">
                      <div className="col-sm-6 sm-pr-0 sm-pl-0 xs-mb-30">
                        <div className="counter-list">
                          <div className="counter-text">
                            <div className="count-number">
                              <CountUp end={50} redraw={true}>
                                {({ countUpRef, start }) => (
                                  <VisibilitySensor onChange={start} delayedCall>
                                    <span ref={countUpRef} className="rs-count plus orange-color"></span>
                                  </VisibilitySensor>
                                )}
                              </CountUp>
                            </div>
                            <h3 className="title">Happy Clients</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 sm-pr-0 sm-pl-0">
                        <div className="counter-list">
                          <div className="counter-text">
                            <div className="count-number">
                              <CountUp end={50} redraw={true}>
                                {({ countUpRef, start }) => (
                                  <VisibilitySensor onChange={start} delayedCall>
                                    <span ref={countUpRef} className="rs-count plus"></span>
                                  </VisibilitySensor>
                                )}
                              </CountUp>
                            </div>
                            <h3 className="title">Project Delivered</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <a className="ot-image-box v3 st1" href="#">
                    <div className="overlay">
                      <h4>Our Mission</h4>
                    </div>
                    <img src="assets/images/engi/image-box1.webp" alt="Our Mission" />
                  </a>
                </div>
                <div className="col-lg-6 col-md-6">
                  <a className="ot-image-box v3 st2" href="#">
                    <div className="overlay">
                      <h4>Our Vision</h4>
                    </div>
                    <img src="assets/images/engi/image-box2.webp" alt="Our Vision" />
                  </a>
                </div>
                <div className="col-lg-6 col-md-6">
                  <a className="ot-image-box v3 st3" href="#">
                    <div className="overlay">
                      <h4>Our Philosophy</h4>
                    </div>
                    <img src="assets/images/engi/image-box3.webp" alt="Our Philosophy" />
                  </a>
                </div>
                <div className="col-lg-6 col-md-6">
                  <a className="ot-image-box v3 st4 mb-0" href="#">
                    <div className="overlay">
                      <h4>Our Strategy</h4>
                    </div>
                    <img src="assets/images/engi/image-box4.webp" alt="Our Strategy" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutOffer