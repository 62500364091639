import React from 'react'
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';

function Business() {
    return (
        <>
            {/* bussiness */}
            <section className="section-business" id='bussines'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="sec-title6">
                                <span className="sub-text new-text">
                                    We transform businesses
                                </span>
                                <h2 className="title new-title">
                                    For over <span>5+</span> years. <br />
                                    For millions of <span>Users</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-7 align-self-end">
                            <p className="mb-0">
                                With 5+ years of experience, we're a global leader in technology solutions, specializing in Mobile App and Web Development, while excelling in SEO, Digital Marketing, UI/UX Design, and Software Development. Our relentless commitment to innovation and excellence has earned us the trust of clients worldwide. We're not just a technology company; we're your partner for digital success.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-clent-h2">
                <div className="container">
                    <div className="row mt--290 justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle cyan-abg">
                                        <span className="number-box">01</span>
                                        <div className="inner">
                                            <p>
                                                Our vision is to foster innovation, professionalism, dedication, and excellence to deliver world-class services and solutions globally.
                                            </p>
                                            <Link to="/about" className="btn-details">
                                                <i className="flaticon-right-arrow" /> LEARN MORE
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <span className="number-box">01</span>
                                        <h3>Our Vision</h3>
                                        <img src="assets/images/engi/flipbox1.webp" height='auto' width='auto' alt="Outsoursing" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle pink2-abg">
                                        <span className="number-box">02</span>
                                        <div className="inner">
                                            <p>
                                                Our mission is to empower startups to evolve into thriving enterprises while fostering collaborative relationships to nurture a vibrant cultural community.
                                            </p>
                                            <Link to="/about" className="btn-details">
                                                <i className="flaticon-right-arrow" /> LEARN MORE
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <span className="number-box">02</span>
                                        <h3>Our Mission</h3>
                                        <img src="assets/images/engi/flipbox2.webp" height='auto' width='auto' alt="Custom Software" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle blue2-abg">
                                        <span className="number-box">03</span>
                                        <div className="inner">
                                            <p>
                                                Our objective is to expedite growth via strategic collaborations and empower our workforce toward greater prosperity.
                                            </p>
                                            <Link to="/about" className="btn-details">
                                                <i className="flaticon-right-arrow" /> LEARN MORE
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <span className="number-box">03</span>
                                        <h3>Our Goal</h3>
                                        <img src="assets/images/engi/flipbox3.webp" height='auto' width='auto' alt="Product Design" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="rs-about" className="rs-about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="sec-title mb-50">
                                <div className="sub-text style4-bg">About Us</div>
                                <h2 className="title pb-20">
                                    Since <span>2018</span>, clients all across the world have put their trust in us.
                                </h2>
                                <div className="desc">
                                    Codeyes provides software, mobile applications, website, development, and other services to satisfy clients needs and enhance their customer relation management needs. Codeyes provides quality services and maintains a high standard for development.
                                </div>
                            </div>
                            <div className="rs-counter style3">
                                <div className="container">
                                    <div className="counter-top-area">
                                        <div className="row">
                                            <div className="col-sm-6 sm-pr-0 sm-pl-0 xs-mb-30">
                                                <div className="counter-list">
                                                    <div className="counter-text">
                                                        <div className="count-number">
                                                            <CountUp end={50} redraw={true}>
                                                                {({ countUpRef, start }) => (
                                                                    <VisibilitySensor onChange={start} delayedCall>
                                                                        <span ref={countUpRef} className="rs-count plus orange-color"></span>
                                                                    </VisibilitySensor>
                                                                )}
                                                            </CountUp>
                                                        </div>
                                                        <h3 className="title">Happy Clients</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 sm-pr-0 sm-pl-0">
                                                <div className="counter-list">
                                                    <div className="counter-text">
                                                        <div className="count-number">
                                                            <CountUp end={50} redraw={true}>
                                                                {({ countUpRef, start }) => (
                                                                    <VisibilitySensor onChange={start} delayedCall>
                                                                        <span ref={countUpRef} className="rs-count plus"></span>
                                                                    </VisibilitySensor>
                                                                )}
                                                            </CountUp>
                                                        </div>
                                                        <h3 className="title">Project Delivered</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="rs-services style3 md-pt-50">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="services-item mb-20 h-100">
                                                <div className="services-icon">
                                                    <div className="image-part">
                                                        <img
                                                            className="main-img"
                                                            src="assets/images/services/style3/main-img/1.webp"
                                                            alt="image"
                                                        />
                                                        <img
                                                            className="hover-img"
                                                            src="assets/images/services/style3/hover-img/1.webp"
                                                            alt="image"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="services-content">
                                                    <div className="services-text">
                                                        <h3 className="title">
                                                            <a href="#">First On Field</a>
                                                        </h3>
                                                    </div>
                                                    <div className="services-desc">
                                                        <p>
                                                            To know volumes and preference patterns of potential market, we do analyze numbers and assess the qualitative insides of current market size.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-5 mt-md-0">
                                            <div className="services-item gold-bg h-100">
                                                <div className="services-icon">
                                                    <div className="image-part">
                                                        <img
                                                            className="main-img"
                                                            src="assets/images/services/style3/main-img/3.webp"
                                                            alt="image"
                                                        />
                                                        <img
                                                            className="hover-img"
                                                            src="assets/images/services/style3/hover-img/3.webp"
                                                            alt="image"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="services-content">
                                                    <div className="services-text">
                                                        <h3 className="title">
                                                            <a href="#">Worldwide Services</a>
                                                        </h3>
                                                    </div>
                                                    <div className="services-desc">
                                                        <p>
                                                            Our development team works closely with clients located thousands of miles away, and this allows us to stay connected with platforms and media that will enable transparency, easy, effectiveness, and accuracy.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-6 mt-5">
                                            <div className="services-item cyan-bg h-100">
                                                <div className="services-icon">
                                                    <div className="image-part">
                                                        <img
                                                            className="main-img"
                                                            src="assets/images/services/style3/main-img/2.webp"
                                                            alt="image"
                                                        />
                                                        <img
                                                            className="hover-img"
                                                            src="assets/images/services/style3/hover-img/2.webp"
                                                            alt="image"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="services-content">
                                                    <div className="services-text">
                                                        <h3 className="title">
                                                            <a href="#">Easy To Reach</a>
                                                        </h3>
                                                    </div>
                                                    <div className="services-desc">
                                                        <p>
                                                            Requiring minimal effort to get your work done.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-5">
                                            <div className="services-item blue-bg h-100">
                                                <div className="services-icon">
                                                    <div className="image-part">
                                                        <img
                                                            className="main-img"
                                                            src="assets/images/services/style3/main-img/4.webp"
                                                            alt="image"
                                                        />
                                                        <img
                                                            className="hover-img"
                                                            src="assets/images/services/style3/hover-img/4.webp"
                                                            alt="image"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="services-content">
                                                    <div className="services-text">
                                                        <h3 className="title">
                                                            <a href="#">24/7 Support</a>
                                                        </h3>
                                                    </div>
                                                    <div className="services-desc">
                                                        <p>
                                                            Get the support while and after project delivery.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Business