import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { testimonials } from '../../../data/data';

const owlOptions = {
    loop: true,
    items: 3,
    margin: 30,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 800,
    dots: false,
    nav: false,
    navSpeed: false,
    center: false,
    responsive: {
        0: {
            items: 1,
            nav: false,
            dots: false,
        },
        768: {
            items: 2,
            nav: false,
            dots: false,
        },
        992: {
            items: 2,
            nav: false,
            dots: false,
        },
        1200: {
            items: 3,
            nav: false,
            dots: true,
        },
    },
};

function Testimonial() {
    return (
        <>
            {/* Testimonial Section Start */}
            <div className="rs-testimonial style8 bg22 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="sec-title6 mb-50 text-center">
                        <span className="sub-text new-text">Testimonials</span>
                        <h2 className="title">Insights from <span>Satisfied Customers</span></h2>
                    </div>
                    <OwlCarousel className="rs-carousel owl-theme" {...owlOptions}>
                        {testimonials.map((data, index) =>
                            <div className="testi-item" key={`testimonal-${index}`}>
                                <div className="author-desc">
                                    <div className="desc">
                                        <img className="quote" src="assets/images/testimonial/main-home/quote11.png" alt="image" />
                                        <p>{data.description}</p>
                                    </div>
                                    <span className="rating">
                                        <img
                                            src="assets/images/testimonial/style2/rating.webp"
                                            height='auto'
                                            width='auto'
                                            alt="Images"
                                        />
                                    </span>
                                    <div className="author-img">
                                        <img src={"assets/images/testimonial/main-home/" + data.img} alt="image" />
                                    </div>
                                </div>
                                <div className="author-part">
                                    <a className="name" href="#">{data.name}</a>
                                    <span className="designation">{data.designation}</span>
                                </div>
                            </div>
                        )}
                    </OwlCarousel>
                </div>
            </div>
            {/* Testimonial Section End */}
        </>

    )
}

export default Testimonial