import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../commonPages/Header'
import CallAction from '../Home/CallAction'
import AboutClient from '../Home/Client'
import AboutCounter from './AboutCounter'
import AboutOffer from './AboutOffer'

function About() {
    return (
        <>
            <Header />
            <div id="content" className="site-content">
                <div className="rs-breadcrumbs img1">
                    <div className="breadcrumbs-inner text-center">
                        <h1 className="page-title">About</h1>
                        <ul>
                            <li title="Codeyes Infotech - IT Solutions and Technology Startup HTML Template">
                                <Link className="active" to="/">
                                    Home
                                </Link>
                            </li>
                            <li>About</li>
                        </ul>
                    </div>
                </div>
                <AboutOffer />
                <AboutClient />
                {/* <AboutTeam /> */}
                <AboutCounter />
            </div>
            <CallAction/>
        </>
    )
}
export default About