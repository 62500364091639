import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const handleLinkClick = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const Footer = () => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const url = 'https://www.codeyesinfotech.com/codeyes-infotech/api/subscribe';

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: new URLSearchParams(formData),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Subscribe API Response:', result);
        // Handle success, e.g., show a success message to the user

        // Display a success toast message
        toast.success(result.message);

        // Reset form data to initial state
        setFormData({
          email: '',
        });
      } else {
        console.error('Subscribe API Failed:', response.status, response.statusText);
        // Handle failure, e.g., show an error message to the user

        // Display an error toast message
        toast.error('Subscription failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error.message);

      // Display an error toast message
      toast.error('An error occurred. Please try again later.');
    }
  };


  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      {/* Footer Start */}
      <footer id="rs-footer" className="rs-footer style1">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
                <div className="footer-logo mb-30">
                  <Link to="/"> <img src="codeyes1.webp" height='auto' width='auto' alt="image" /></Link>
                </div>
                <div className="textwidget pb-30">
                  <p>We are leading Mobile App and Web Development technology solutions providing company all over the world doing over 5+ years.</p>
                </div>
                <ul className="footer-social md-mb-30">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61553690227394" target="_blank" aria-label="CodeYes Infotech Facebook Page"><span><i className="fa fa-facebook" /></span></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/codeyesinfotech/" target="_blank" aria-label="CodeYes Infotech Instagram Page"><span><i className="fa fa-instagram" /></span></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/codeyes-infotech/" target="_blank" aria-label="CodeYes Infotech LinkedIn Page"><span><i className="fa fa-linkedin" /></span></a>
                  </li>
                  <li>
                    <a href="https://www.google.com/search?q=codeyes+infotech " target="_blank" aria-label="Search for CodeYes Infotech on Google"><span><i className="fa fa-google" /></span></a>
                  </li>
                  {/* <li> 
                    <a href="# " target="_blank"><span><i className="fa fa-pinterest-p" /></span></a> 
                  </li> */}

                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 pl-45 md-pl-15 md-mb-30">
                <h3 className="widget-title">IT Services</h3>
                <ul className="site-map">
                  <li><Link to="/web-development" onClick={handleLinkClick}>Web Development</Link></li>
                  <li><Link to="/mobile-development" onClick={handleLinkClick}>Mobile App Development</Link></li>
                  <li><Link to="/ui-ux-design" onClick={handleLinkClick}>UI/UX Design</Link></li>
                  <li><Link to="/seo" onClick={handleLinkClick}>SEO</Link></li>
                  <li><Link to="/software-development" onClick={handleLinkClick}>Software Development</Link></li>
                  <li><Link to="/digital-marketing" onClick={handleLinkClick}>Digital Marketing</Link></li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 md-mb-30">
                {/* <h3 className="widget-title text-center">Contact Info</h3> */}

                <div className='row'>
                    <div className='col-6'>
                    <div className='row pt-10'>
                        <span>
                          <img src='assets/images/india-flag.png' className='w-100 mr-10'></img>
                        </span>
                        <h5 className='pt-1 pl-10'>India</h5>

                          {/* <div className='col-3'>
                            <img src='assets/images/india-flag.png' className='w-100'></img>
                          </div>
                          <div className='col-9'>
                            <h5>India</h5>
                          </div> */}
                      </div>

                      <ul className="address-widget">
                        <li>
                          <i className="flaticon-location" />
                          <div className="desc">213, Skyview Business Horizon, nr. Shyamdham Temple, Sarthana Jakat Naka, Surat</div>
                        </li>
                        <li>
                          <i className="flaticon-call" />
                          <div className="desc">
                            <a href="tel:+918849723987" className='codeyestext'>(+91) 88497 23987</a>
                          </div>
                        </li>
                        <li>
                          <i className="flaticon-email" />
                          <div className="desc">
                            <a
                              href="https://mail.google.com/mail/?view=cm&fs=1&to=codeyesinfotech@gmail.com"
                              target="_blank"
                              rel="noopener noreferrer"
                              className='codeyestext'
                            >
                              info@codeyesinfotech.com
                            </a>
                          </div>
                        </li>
                        {/* <li>
                          <i className="flaticon-linkedin" />
                          <div className="desc">
                            Opening Hours: 10:00 - 18:00
                          </div>
                        </li> */}
                      </ul>
                    </div>

                    <div className='col-6'>
                      <div className='row pt-10'>
                          <span>
                            <img src='assets/images/uk-flag.png' className='w-100 mr-10'></img>
                          </span>
                          <h5 className='pt-1 pl-10'>United Kingdom</h5>

                            {/* <div className='col-3'>
                              <img src='assets/images/india-flag.png' className='w-100'></img>
                            </div>
                            <div className='col-9'>
                              <h5>India</h5>
                            </div> */}
                      </div>

                      <ul className="address-widget">
                        <li>
                          <i className="flaticon-location" />
                          <div className="desc">6-8 Dudley Street, Luton, England</div>
                        </li>
                        <li>
                          <i className="flaticon-call" />
                          <div className="desc">
                            <a href="tel:+918849723987" className='codeyestext'>(+44) 7880205836</a>
                          </div>
                        </li>
                        <li>
                          <i className="flaticon-email" />
                          <div className="desc">
                            <a
                              href="https://mail.google.com/mail/?view=cm&fs=1&to=codeyesinfotech@gmail.com"
                              target="_blank"
                              rel="noopener noreferrer"
                              className='codeyestext'
                            >
                              info@codeyesinfotech.com
                            </a>
                          </div>
                        </li>
                        {/* <li>
                          <i className="flaticon-linkedin" />
                          <div className="desc">
                            Opening Hours: 10:00 - 18:00
                          </div>
                        </li> */}
                      </ul>
                    </div>
                </div>


              </div>
              {/* <div className="col-lg-3 col-md-12 col-sm-12">
                <h3 className="widget-title">Get in Touch</h3>
                <p className="widget-desc">For any inquiries, assistance, or collaboration opportunities, please don't hesitate to contact us. Our dedicated team is here to provide you with the support and information you need.</p>
                <p>
                  <input type="email" name="email" required value={formData.email} onChange={handleFormChange} placeholder="Your email address" />
                  <em className="paper-plane"><input type="submit" onClick={handleFormSubmit} defaultValue="Sign up" /></em>
                  <i className="flaticon-send" />
                </p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 text-right md-mb-10 order-last">
                <ul className="copy-right-menu">
                  <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
                  <li><Link to="/about" onClick={handleLinkClick}>About</Link></li>
                  <li><Link to="/web-development" onClick={handleLinkClick}>Services</Link></li>
                  <li><Link to="/portfolio" onClick={handleLinkClick}>Projects</Link></li>
                  <li><Link to="/why-choose-us" onClick={handleLinkClick}>Why Us</Link></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="copyright">
                  <p>© {new Date().getFullYear()} All Rights Reserved. Developed By <a href="https://www.google.com/search?q=codeyes+infotech">Codeyes Infotech</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer End */}
    </>
  );
};

export default Footer;
