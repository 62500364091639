import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../commonPages/Header'
import CallAction from '../Home/CallAction'
import WhyUs from './WhyUs'

function DigitalMarketing() {
    return (
        <>
            <Header />
            <div id="content" className="site-content">
                <div className="rs-breadcrumbs img3">
                    <div className="breadcrumbs-inner text-center">
                        <h1 className="page-title">Digital Marketing</h1>
                        <ul>
                            <li title="Codeyes Infotech - IT Solutions and Technology Startup HTML Template">
                                <Link className="active" to="/">
                                    Home
                                </Link>
                            </li>
                            <li title="Go To Services">
                                <Link className="active" to="#">
                                    Services
                                </Link>
                            </li>
                            <li>Digital Marketing</li>
                        </ul>
                    </div>
                </div>
                <div id="rs-about" className="rs-about services pt-120 pb-120 md-pt-70">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="sec-title mb-50">
                                    <div className="sub-text style4-bg">Digital Marketing</div>
                                    <h2 className="title pb-20">
                                        Digital Marketing
                                    </h2>
                                    <div className="desc">
                                        Drive your business's expansion using our outstanding digital marketing solutions. We specialize in enhancing your online reputation and expanding your customer base through our impactful digital marketing services. Our suite of digital marketing solutions is designed to promote business growth effectively.
                                    </div>
                                    <ul className="listing-style mt-30">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Conversion Rate Optimization (CRO)</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Pay-Per-Click Advertising</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>PPC Services</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Content Strategy and Creation</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Search Engine Optimization – SEO</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="about-img">
                                    <img src="assets/images/about/about-16.webp" alt="About" />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6" />
                                    <div className="col-lg-6">
                                        <div className="addon-services">
                                            <div className="icon-images">
                                                <img src="assets/logo/logo512.png" alt="Images" />
                                            </div>
                                            <div className="services-text">
                                                <h2 className="title"> Leading Codeyes Agency Since 2018</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WhyUs />
            </div>
            <CallAction />
        </>
    )
}

export default DigitalMarketing