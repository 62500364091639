import React from 'react';
import { Link } from 'react-router-dom';
import { servicedetails } from '../../../data/data';

const handleLinkClick = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

function Services() {
    return (
        <>
            {/* Services */}
            <div id="rs-services" className="rs-services style7 pt-110 md-pt-80">
                <div className="container">
                    <div className="sec-title6 mb-50 text-center">
                        <span className="sub-text new-text">
                            services
                        </span>
                        <h2 className="title new-title">
                            Equipped for your <span>success</span>, we deliver truly outstanding <span>IT solutions</span>.
                        </h2>
                    </div>
                    <div className="animation-layer">
                        <div className="shape-layer">
                            <img
                                className="dance2"
                                src="assets/images/services/style9/layer-icon.webp"
                                alt="Images"
                            />
                        </div>
                    </div>
                    <div className="rs-services style2 md-mb-30 md-pt-80 md-pb-80">
                        <div className="container">
                            <div className="row">
                                {servicedetails.map((data, index) =>
                                    <div className="col-lg-4 col-md-6 mb-25" key={`client-${index}`}>
                                        <div className="flip-box-inner h-100">
                                            <div className="flip-box-wrap h-100">
                                                <div className="front-part h-100">
                                                    <div className="front-content-part h-100">
                                                        <div className="front-icon-part">
                                                            <div className="icon-part">
                                                                <img
                                                                    src={"assets/images/services/main-home/icons/" + data.img1}
                                                                    alt="image"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="front-title-part">
                                                            <h3 className="title">
                                                                <Link to={data.slug}>
                                                                    {data.title}
                                                                </Link>
                                                            </h3>
                                                        </div>
                                                        <div className="front-desc-part">
                                                            <p>
                                                                {data.desc}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"back-front h-100 " + data.bgclass}>
                                                    <div className="back-front-content">
                                                        <div className="back-title-part">
                                                            <h3 className="back-title">
                                                                <Link to={data.slug} onClick={handleLinkClick}>
                                                                    {data.title}
                                                                </Link>
                                                            </h3>
                                                        </div>
                                                        <div className="back-desc-part">
                                                            <p className="back-desc">
                                                                {data.desc}
                                                            </p>
                                                        </div>
                                                        <div className="back-btn-part">
                                                            <div className="services-btn3">
                                                                <Link to={data.slug} className='readon' onClick={handleLinkClick}>Learn More</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Services