import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
    const [isSticky, setIsSticky] = useState(false);
    const [pathname, setPathname] = useState(window.location.pathname);
    const [isOpen, setIsOpen] = useState(false)
    const [miniNav, setMiniNav] = useState(false)

    const variants = {
        open: { x: 0 },
        closed: { x: '100%' },
    }
    useEffect(() => {
        const handleScroll = () => {
            const scroll = window.scrollY;
            const header = document.querySelector('.menu-sticky');
            const rsHeader = document.querySelector('#rs-header');
            const sections = document.querySelectorAll('section');

            if (scroll < 1) {
                setIsSticky(false);
            } else {
                setIsSticky(true);
            }

            sections.forEach((section) => {
                const elementTop = section.offsetTop - rsHeader.offsetHeight;
                if (scroll >= elementTop) {
                    section.classList.add('loaded');
                }
            });
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleopenPopup = () => {
        const navExpander = document.getElementById('nav-expander');
        const navClose = document.getElementById('nav-close');
        const offwrap = document.querySelector('.offwrap');
        if (navExpander && navClose && offwrap) {
            document.body.classList.add('nav-expanded');
        }
    };
    const handleclosePopup = () => {
        document.body.classList.remove('nav-expanded');
    };

    const handleLinkClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setPathname(window.location.pathname);
    };


    return (
        <>
            <div className="full-width-header">
                <header
                    id="rs-header"
                    className="rs-header style4 modify1 header-transparent"
                >
                    <div className={isSticky ? 'menu-area menu-sticky sticky' : ' menu-area menu-sticky'}>
                        <div className="container box-layout">
                            <div className="row align-items-center">
                                <div className="col-lg-2">
                                    <div className="logo-part">
                                        <Link to="/">
                                        <img src="codeyes1.webp" height='auto' width='auto' alt="logo" />
                                        </Link>
                                    </div>
                                    <div className="mobile-menu">
                                        <Link
                                            to="#"
                                        >
                                            <i onClick={() => setIsOpen(!isOpen)} className="fa fa-bars" />
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-lg-8 text-right">
                                    <div className="rs-menu-area">
                                        <div className="main-menu">
                                            <nav className="rs-menu">
                                                <ul id="nav" className="nav-menu">
                                                    <li className={`limenu  ${pathname === '/' ? 'current-menu-item' : ''}`}>
                                                        <Link to="/" onClick={handleLinkClick} >Home</Link>
                                                    </li>
                                                    <li className={`limenu  ${pathname === '/about' ? 'current-menu-item' : ''}`}>
                                                        <Link to="/about" onClick={handleLinkClick}>About</Link>
                                                    </li>
                                                    <li className={`limenu menu-item-has-children ${(pathname === '/web-development' || pathname === '/mobile-development' || pathname === '/ui-ux-design' || pathname === '/seo' || pathname === '/software-development' || pathname === '/digital-marketing') ? 'current-menu-item' : ''}`}>
                                                        <Link to="#" onClick={handleLinkClick}>Services</Link>
                                                        <ul className="sub-menu">
                                                            <li className={`${pathname === '/web-development' ? 'current-menu-item' : ''}`}>
                                                                <Link to="/web-development" className='submenu-li-a' onClick={handleLinkClick}>Web Development</Link>{" "}
                                                            </li>
                                                            <li className={`${pathname === '/mobile-development' ? 'current-menu-item' : ''}`}>
                                                                <Link to="/mobile-development" className='submenu-li-a' onClick={handleLinkClick}>Mobile App Development</Link>
                                                            </li>
                                                            <li className={`${pathname === '/ui-ux-design' ? 'current-menu-item' : ''}`}>
                                                                <Link to="/ui-ux-design" className='submenu-li-a' onClick={handleLinkClick}>UI/UX Design</Link>
                                                            </li>
                                                            <li className={`${pathname === '/seo' ? 'current-menu-item' : ''}`}>
                                                                <Link to="/seo" className='submenu-li-a' onClick={handleLinkClick}>SEO</Link>
                                                            </li>
                                                            <li className={`${pathname === '/software-development' ? 'current-menu-item' : ''}`}>
                                                                <Link to="/software-development" className='submenu-li-a' onClick={handleLinkClick}>Software Development</Link>
                                                            </li>
                                                            <li className={`${pathname === '/digital-marketing' ? 'current-menu-item' : ''}`}>
                                                                <Link to="/digital-marketing" className='submenu-li-a' onClick={handleLinkClick}>Digital Marketing</Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className={`limenu  ${pathname === '/portfolio' ? 'current-menu-item' : ''}`}>
                                                        <Link to="/portfolio" onClick={handleLinkClick}>Projects</Link>
                                                    </li>
                                                    <li className={`limenu  ${pathname === '/why-choose-us' ? 'current-menu-item' : ''}`}>
                                                        <Link to="/why-choose-us" onClick={handleLinkClick}>Why Choose Us</Link>
                                                    </li>
                                                    <li className={`limenu  ${pathname === '/contact' ? 'current-menu-item' : ''}`}>
                                                        <Link to="/contact" onClick={handleLinkClick}>Contact</Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="expand-btn-inner search-icon hidden-md">
                                        <ul>
                                            <li className="quote-btn">
                                                <Link id="nav-expander" to="#" onClick={handleopenPopup}>Get In Touch</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <nav className="right_menu_togle hidden-md">
                    <div className="close-btn">
                        <div className="nav-link">
                            <button id="nav-close" className="close" type="button" aria-label="Close" onClick={handleclosePopup}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="canvas-logo">
                        <Link to="/">
                            <img src="codeyes1.webp" height='auto' width='auto' alt="logo" />
                        </Link>
                    </div>
                    <div className="offcanvas-text">
                        <p>
                            We are leading Mobile App and Web Development technology solutions providing company all over the world doing over 5+ years.
                        </p>
                    </div>
                    <div className="canvas-contact">
                        <div className="address-area">
                            <div className="address-list">
                                <div className="info-icon">
                                    <i className="flaticon-call" />
                                </div>
                                <div className="info-content">
                                    <h4 className="title">Contact</h4>
                                    <em>
                                        <a href="tel:+918849723987" className='codeyestext'>(+91) 88497 23987</a>
                                    </em>
                                </div>
                            </div>

                            <div className="address-list">
                                <div className="info-icon">
                                    <i className="flaticon-email" />
                                </div>
                                <div className="info-content">
                                    <h4 className="title">Email</h4>
                                    <em>
                                        <a
                                            href="https://mail.google.com/mail/?view=cm&fs=1&to=codeyesinfotech@gmail.com"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className='codeyestext'
                                        >
                                            codeyesinfotech@gmail.com
                                        </a>
                                    </em>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                {/* Canvas Menu end */}
            </div>
            {/*Full width header End*/}

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        className="responsivenavbar"
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={variants}
                        transition={{ ease: 'easeOut', duration: 0.2 }}
                    >
                        <div className="closebtn">
                            <svg
                                onClick={() => setIsOpen(false)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-x-lg"
                                viewBox="0 0 16 16"
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </div>
                        <ul className='resnavbar'>
                            <li >
                                <Link to="/" onClick={handleLinkClick} >Home</Link>
                            </li>
                            <li >
                                <Link to="/about" onClick={handleLinkClick}>About</Link>
                            </li>
                            <li onClick={() => setMiniNav(!miniNav)} >
                                <Link to="#" onClick={handleLinkClick}>Services {miniNav ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>}</Link>
                                {miniNav && (
                                    <ul className='servicediv'>
                                        <li >
                                            <Link to="/web-development" onClick={handleLinkClick}>Web Development</Link>{" "}
                                        </li>
                                        <li >
                                            <Link to="/mobile-development" onClick={handleLinkClick}>Mobile App Development</Link>
                                        </li>
                                        <li >
                                            <Link to="/ui-ux-design" onClick={handleLinkClick}>UI/UX Design</Link>
                                        </li>
                                        <li >
                                            <Link to="/seo" onClick={handleLinkClick}>SEO</Link>
                                        </li>
                                        <li >
                                            <Link to="/software-development" onClick={handleLinkClick}>Software Development</Link>
                                        </li>
                                        <li >
                                            <Link to="/digital-marketing" onClick={handleLinkClick}>Digital Marketing</Link>
                                        </li>
                                    </ul>)}
                            </li>
                            <li >
                                <Link to="/portfolio" onClick={handleLinkClick}>Projects</Link>
                            </li>
                            <li >
                                <Link to="/why-choose-us" onClick={handleLinkClick}>Why Choose Us</Link>
                            </li>
                            <li >
                                <Link to="/contact" onClick={handleLinkClick}>Contact</Link>
                            </li>
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default Header