import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { clientDetail } from '../../../data/data';

const owlOptions = {
    loop: true,
    items: 5,
    margin: 30,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 800,
    dots: false,
    nav: false,
    navSpeed: false,
    center: false,
    responsive: {
        0: {
            items: 1,
            nav: false,
            dots: false,
        },
        768: {
            items: 2,
            nav: false,
            dots: false,
        },
        992: {
            items: 3,
            nav: false,
            dots: false,
        },
        1200: {
            items: 4,
            nav: false,
            dots: false,
        },
    },
    // Add the afterInit and onChanged callbacks to handle accessibility
    afterInit: setAriaLabels,
    onChanged: setAriaLabels,
};

// Function to set ARIA labels for Owl Carousel dots
function setAriaLabels() {
    const dots = document.querySelectorAll('.owl-dot');

    dots.forEach((dot, index) => {
        const slideNumber = index + 1;
        dot.setAttribute('aria-label', `Slide ${slideNumber}`);
    });
}

function Client() {
    return (
        <>
            {/* Partner Start */}
            <div className="rs-partner style2 pt-110 pb-70 md-pt-70">
                <div className="container">
                    <OwlCarousel className="rs-carousel owl-theme" {...owlOptions}>
                        {clientDetail.map((data, index) => (
                            <div className="partner-item" key={`client-${index}`} style={data.id === 12 ? { filter: 'invert(1)' } : {}}>
                                <div className="logo-img">
                                    <a href="https://codeyesinfotech.com/">
                                        <img src={`assets/images/partner/style2/${data.logo}`} alt="image" />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div>
            {/* Partner End */}
        </>
    );
}

export default Client;
