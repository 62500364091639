import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WOW from 'wowjs';
import Home from "../components/userLayout/Home/Home";
import Layout from "../components/userLayout/Layout";
import 'owl.carousel/dist/assets/owl.carousel.css'; // Import the Owl Carousel CSS
import About from "../components/userLayout/About/About";
import WhyChooseUs from "../components/userLayout/WhyChooseUs/WhyChooseUs";
import Contact from "../components/userLayout/Contact/Contact";
import Webapp from "../components/userLayout/Services/Webapp";
import Mobileapp from "../components/userLayout/Services/Mobileapp";
import Projects from "../components/userLayout/Projects/Projects";
import DigitalMarketing from "../components/userLayout/Services/DigitalMarketing";
import SEO from "../components/userLayout/Services/SEO";
import UiUxdesign from "../components/userLayout/Services/UiUxdesign";
import Softwareapp from "../components/userLayout/Services/Softwareapp";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => {
    new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      mobile: false,
      offset: 0,
      live: true,
      callback: function (box) {
      },
      scrollContainer: null
    }).init();
  }, [])

  return (
    <>
    <ToastContainer  autoClose={3000}/>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/why-choose-us' element={<WhyChooseUs />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/portfolio' element={<Projects />} />
            <Route path='/web-development' element={<Webapp />} />
            <Route path='/mobile-development' element={<Mobileapp />} />
            <Route path='/ui-ux-design' element={<UiUxdesign />} />
            <Route path='/seo' element={<SEO />} />
            <Route path='/software-development' element={<Softwareapp />} />
            <Route path='/digital-marketing' element={<DigitalMarketing />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
