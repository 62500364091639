import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../commonPages/Header';

function Contact() {
    const [formData, setFormData] = useState({
        full_name: '',
        number: '',
        email: '',
        message: '',
    });

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const url = 'https://www.codeyesinfotech.com/codeyes-infotech/api/contact-us';

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: new URLSearchParams(formData),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            if (response.ok) {
                const result = await response.json();
                toast.success(result.message);

                // Reset form data to initial state
                setFormData({
                    full_name: '',
                    number: '',
                    email: '',
                    message: '',
                });
            } else {
                console.error('Contact Us API Failed:', response.status, response.statusText);
                toast.error("Fill The Form Correctly");
            }
        } catch (error) {
            console.error('Error:', error.message);
            toast.error(error.message);
        }
    };


    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    return (
        <>
            {/* Contact */}
            <div className="main-content">
                <Header />
                <div className="rs-breadcrumbs img2">
                    <div className="breadcrumbs-inner text-center">
                        <h1 className="page-title">Contact</h1>
                        <ul>
                            <li title="Codeyes Infotech - IT Solutions and Technology Startup HTML Template">
                                <Link className="active" to="/">
                                    Home
                                </Link>
                            </li>
                            <li>Contact</li>
                        </ul>
                    </div>
                </div>
                <div className="rs-why-choose style2 gray-color rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-7 md-mb-30">
                                <div className="sec-title mb-40">
                                    <div className="sub-text style4-bg left">Let's Talk</div>
                                    <h2 className="title pb-20">
                                        Make<span> Contact</span> With Us
                                    </h2>
                                    <div className="desc">
                                        Send us a message! We'll schedule a phone call or in-person
                                        <br />
                                        meeting to learn more about your company's requirements.
                                        <br />There is no fee for any of the main conferences.
                                    </div>
                                </div>
                                <div className="services-wrap mb-25">
                                    <div className="services-icon">
                                        <img src="assets/images/choose/icons/style2/1.webp" alt="image" />
                                    </div>
                                    <div className="services-text">
                                        <h3 className="title">
                                            <a href="#">Email</a>
                                        </h3>
                                        <p className="services-txt">
                                            <a
                                                href="https://mail.google.com/mail/?view=cm&fs=1&to=codeyesinfotech@gmail.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className='codeyesemail'
                                            >
                                                codeyesinfotech@gmail.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="services-wrap mb-25">
                                    <div className="services-icon">
                                        <img src="assets/images/choose/icons/style2/2.png" alt="image" />
                                    </div>
                                    <div className="services-text">
                                        <h3 className="title">
                                            <a href="#">Call Us</a>
                                        </h3>
                                        <a className="services-txt codeyestext" href="tel:+918849723987"> (+91) 88497 23987</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <div className="rs-contact mod1">
                                    <div className="contact-wrap">
                                        <div className="content-part mb-25">
                                            <h2 className="title mb-15">Schedule Appointment</h2>
                                            <p className="desc">We here to help you 24/7 with experts</p>
                                        </div>
                                        <div id="appointment-messages" />
                                        <form
                                            id="appointment-form"
                                            method="post"
                                            action="appointment.php"
                                            onSubmit={handleFormSubmit}
                                        >
                                            <fieldset>
                                                <div className="row">
                                                    <div className="col-lg-12 mb-15">
                                                        <input
                                                            className="from-control"
                                                            type="text"
                                                            id="appointment_name"
                                                            placeholder="Name"
                                                            required
                                                            name="full_name"
                                                            value={formData.full_name} onChange={handleFormChange}
                                                        />
                                                    </div>
                                                    <div className="col-lg-12 mb-15">
                                                        <input
                                                            className="from-control"
                                                            type="text"
                                                            id="appointment_email"
                                                            name="email" value={formData.email} onChange={handleFormChange}
                                                            placeholder="E-Mail"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-lg-12 mb-15">
                                                        <input
                                                            className="from-control"
                                                            type="text"
                                                            id="appointment_phone"
                                                            name="number" value={formData.number} onChange={handleFormChange}
                                                            placeholder="Phone Number"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-lg-12 mb-25">
                                                        <textarea
                                                            className="from-control"
                                                            id="message"
                                                            name="message" value={formData.message} onChange={handleFormChange}
                                                            placeholder="Your message Here"
                                                            required
                                                        // defaultValue={""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group mb-0">
                                                    <input
                                                        className="submit-btn orange-btn"
                                                        type="submit"
                                                        defaultValue="Submit Now"
                                                    />
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact