import React from 'react'
import { Link } from 'react-router-dom'
import { mobileapptechnology } from '../../../data/data'
import Header from '../../commonPages/Header'
import CallAction from '../Home/CallAction'
import WhyUs from './WhyUs'


function Mobileapp() {
    return (
        <>
            <Header />
            <div id="content" className="site-content">
                <div className="rs-breadcrumbs img3">
                    <div className="breadcrumbs-inner text-center">
                        <h1 className="page-title">Mobile Application Development</h1>
                        <ul>
                            <li title="Codeyes Infotech - IT Solutions and Technology Startup HTML Template">
                                <Link className="active" to="/">
                                    Home
                                </Link>
                            </li>
                            <li title="Go To Services">
                                <Link className="active" to="#">
                                    Services
                                </Link>
                            </li>
                            <li>Mobile Application Development</li>
                        </ul>
                    </div>
                </div>
                <div id="rs-about" className="rs-about services pt-120 pb-120 md-pt-70">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="sec-title mb-50">
                                    <div className="sub-text style4-bg">Mobile Application Development</div>
                                    <h2 className="title pb-20">
                                        Mobile Application Development
                                    </h2>
                                    <div className="desc">
                                        Dedicated to creating cutting-edge mobile app solutions for businesses across a range of industries, this company develops applications for iOS and Android. Our team of gifted mobile app developers is known for creating cutting-edge Android and iOS app development solutions. The mobile apps we create are adaptable to a variety of devices, including tablets, smartphones, smart TVs, wearable technology, headsets, and more, to reach a larger audience.
                                    </div>
                                    <ul className="listing-style mt-30">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Increase brand exposure and revenue by streamlining processes, increasing efficiency, and engaging consumers.</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Stay competitive, maximize ROI, and ensure long-term success.</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Increased Workflow Efficiency</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Attract user attention and gain a competitive advantage by building a strong brand image.</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>Improve the accuracy, functionality, scalability, and performance of your applications. </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>For best outcomes, strengthen security, assure compatibility, and provide a smooth user experience.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="about-img">
                                    <img src="assets/images/about/about-16.webp" alt="About" />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6" />
                                    <div className="col-lg-6">
                                        <div className="addon-services">
                                            <div className="icon-images">
                                                <img src="assets/logo/logo512.png" alt="Images" />
                                            </div>
                                            <div className="services-text">
                                                <h2 className="title"> Leading Codeyes Agency Since 2018</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rs-technology gray-color pt-120 pb-120 md-pt-80 md-pb-80">
                    <div className="container">
                        <div className="sec-title2 text-center mb-45">
                            <span className="sub-text gold-color">Technology Index</span>
                            <h2 className="title title2">
                                What Technology We Are Using For Our Valued Customers
                            </h2>
                        </div>
                        <div className="row" style={{justifyContent:'center'}}>
                            {mobileapptechnology.map((data, index) =>
                                <div className="col-lg-2 col-md-4 col-sm-4 col-6" key={`web-${index}`}>
                                    <div className="technology-item">
                                        <a href="#">
                                            <div className="logo-img" >
                                                <img src={"assets/images/technology/style2/Mobile/" + data.Image} alt="image"  />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="btn-part mt-30">
                            <Link  className="readon started" to="/contact">
                                Get Started
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <WhyUs />
            </div>
            <CallAction />
        </>
    )
}

export default Mobileapp