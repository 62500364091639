import React from 'react';
import Header from '../../commonPages/Header';
import Banner from './Banner';
import Business from './Business';
import CallAction from './CallAction';
import Client from './Client';
import Project from './Project';
import Services from './Services';
import Testimonial from './Testimonial';

const Home = () => {

    return (
        <>
            {/* Main content Start */}
            <div className="main-content">
                <Header />
                <Banner />
                <Business />
                <Services />
                <Project />
                <Testimonial />
                <Client />
                <CallAction />
            </div>
            {/* Main content End */}
        </>
    )
}

export default Home

