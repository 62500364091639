import React from 'react'
import { Link } from 'react-router-dom'

const handleLinkClick = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	})
}

function Banner() {
	return (
		<>
			<div className="rs-banner style8">
				<div className="container">
					<div className="banner-content text-center">
						<span className="sub-title">
							Welcome to Codeyes Infotech
						</span>
						<h1 className="title">
							Providing Top-Tier <span>IT Services</span> and
							Innovative <span>Solutions</span> .
						</h1>
						<ul className="banner-btn">
							<li>
								<Link
									className="readon started get-ready3"
									to="/contact"
								>
									Get Started
								</Link>
							</li>
							<li>
								<Link
									className="readon qoute-btn active"
									to="/about"
									onClick={handleLinkClick}
								>
									Discover More
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export default Banner
